<template>
  <div class="account-sec">
    <div class="center-title">换绑手机</div>
    <div class="option">当前登录账号：<span class="text">{{userInfo.name}}</span></div>
    <div class="option">当前绑定手机：<span class="text">{{userInfo.phone}}</span></div>
    <div class="form-text phone">
      <input class="form-input" type="text" v-model="newPhone">
    </div>
    <div class="err-tip">{{phoneErr}}</div>
    <div class="code-box">
      <div class="form-text code">
        <input class="form-input" type="text">
      </div>
      <span class="get-code" @click="getCode">获取验证码</span>
    </div>
    <div class="err-tip">{{codeErr}}</div>
    <button class="subBtn" @click="submitBtn">确认换绑</button>
  </div>
</template>
<script>
export default {
  name: 'AccountSecurity',
  data(){
    return {
      userInfo: '',
      newPhone: '',
      phoneErr: '',
      codeErr: ''
    }
  },
  mounted(){
    this.getUserInfo(); //获取用户信息
  },
  methods: {
    //获取用户信息
    getUserInfo(){
      let info = localStorage.getItem('user');

      if(info){
        this.userInfo = JSON.parse(info);
        this.userInfo.phone = this.userInfo.phone.substring(0,3) + '****' + this.userInfo.phone.substring(7,11);
      }
    },
    //手机号验证
    newPhoneV(newPhone){
      let reg = /^0?1[3|4|5|7|8|9][0-9]\d{8}$/;

      if(!reg.test(newPhone)){
        this.phoneErr = '请填写正确的手机号'
      }else{
        this.phoneErr = '';
      }
    },
    //获取验证码
    getCode(){
      if(!this.newPhone){
        this.phoneErr = '请填写手机号码'
        return ;
      }

      if(this.phoneErr == ''){
        this.$axios.post(`/v1/login/sendResetPwdSmsCode`, {
          phone: this.newPhone
        }).then(res => {
          this.codeErr = res.msg;
        })
      }
    },
    //提交
    submitBtn(){
      // if(!this.phoneErr && !this.codeErr){
      //   this.$axios.post(`/v1/login/sendResetPwdSmsCode`, {
      //     phone: this.newPhone
      //   }).then(res => {
      //     this.codeErr = res.msg;
      //   })
      // }
    }
  },
  watch: {
    newPhone: 'newPhoneV'
  }
}
</script>

<style scoped lang="scss">
.option{
  font-size: 14px;
  color: #999;
  margin-bottom: 14px;
  .text{
    font-size: 14px;
    color: #333;
  }
}
.form-text{
  width: 290px;
}
.subBtn{
  padding: 10px 24px;
  background: #254ED4;
  border-radius: 6px;
  font-size: 16px;
  color: #FFF;
  margin-top: 54px;
  cursor: pointer;
}
.code-box{
  display: flex;
  align-items: center;
  margin-top: 24px;
  .get-code{
    font-size: 12px;
    color: #254ED4;
    margin-left: 12px;
    cursor: pointer;
  }
}
</style>
